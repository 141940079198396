/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  index.css
Author:    Aaron D. Muilne
Version:   2.09
Description:
The core css for the Customer Portal. These styles will rely heavily on the concepts in EveryLayout (in CSS) by Andy Bell and Heydon Pickering.
To create component specific modifiers to these styles create an additional css file that matches the name of the js file that will make use of those classes.

History:
2.09  14-07-23 DBL   LD12171 Added a disabled hover effect
2.08  03-04-23 DBL   LD0012022 Removed GBTs colours into theming
2.07  29-03-23 DBL   Make React Select above error boxes
2.06  13-10-22 DBL   Colour the React Select menu to indicate invalid options
2.05  23-08-22 DBL   Added #modal-root > :not(:last-child)
2.04  22-07-22 DBL   Added classes for ReactSelect__
2.03  22-04-21 ADM   Added util class of margin-block-start. Moved .cluster-no-wrap from newQuote.css to index.css. Created .stack-center to center justify the icons for the accept quote screen. Reinstated a media query to condense the quotes/pickups tables (#tablessuck). Added the class no-measure, table & tr to the selector that removes max inline sizing
2.02  22-04-20 ADM   Reinstated general table styles (for summary page).
 Added textarea styles (key being width and height). Aadded hover and focus states for error-outline. Tinkered with the selectors involving .checked to manage specificity / maintain other screens. Added padding to the block end (bottom) of the .center class to keep the content from gluing to the bottom of the screen.
2.02  22-04-19 ADM   Added the util class .flex-grow. This tells flex items to grow. Added margin-block-end of 10.45rem to btn__add for narrow screens (less than 701px). Reimplement .error-msg to colour the warning svgs. Wrapped the .card svg selector with :where() to reset the specificity. Changed height for inputs and selects to be 100%. Added padding-inline of 1rem to center. This keeps it from being glued to the sides of the browser window on narrow screens.
2.01  22-04-13 ADM   Added a modular scale. Added --measure property to define an axiom for maxwidth. Added the 'primitives' as layed out in Everyday layout
2.00	22-04-13 ADM   Created - removing most styles. The reset and custom properties carried over, along with the custom radio buttons.
----------------------------------- */

/* Start Reset - inpsired by/learning from Kevin Powell - Don't touch! */

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
figure {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  line-height: 1;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

p {
  margin: 0;
}

ul[class] {
  margin: 0;
  padding: 0;
  list-style: none;
}

button,
input[type="submit"],
input[type="number"],
textarea,
select,
option {
  border: none;
  font-family: inherit;
  font-size: inherit;
}

input[type="text"] {
  font-family: inherit;
  font-size: inherit;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-inline-size: 100%;
}
/* End Reset */

/**************** 
Intrinsic Sizing
****************/
* {
  max-inline-size: var(--measure);
}

html,
body,
header,
nav,
main,
form,
select,
textarea,
div,
section,
table,
tr,
footer,
.no-measure,
.accent::before,
.card h2::after,
.cardForm-container > h2 {
  max-inline-size: none;
}

/**************** 
Typography
****************/

body {
  font-family: var(--ff-sans);
  font-size: 1.125rem;
}

h1,
.h1 {
  font-weight: 700;
}

h2,
.h2,
h3,
.h3 {
  font-weight: 600;
}

h2,
.h2 {
  font-size: var(--s2);
}

h3,
.h3 {
  font-size: var(--s1);
}

a,
abbr {
  text-decoration: none;
}

p {
  overflow-wrap: break-word;
  max-inline-size: inherit;
  /* hyphens: auto; */
}

.error-msg,
.warning-msg {
  font-weight: 600;
}

.error-msg > svg {
  color: var(--clr-validation-invalid-400);
}

.error-outline {
  z-index: 13;
  color: var(--clr-validation-invalid-400);
  outline: 3px solid var(--clr-validation-invalid-400);
  border: 2px solid var(--clr-validation-invalid-400) !important; /* regardless of specificity, override styles to make the error clear */
}

.error-outline,
input[type="number"]:focus,
input[type="text"]:focus,
select:not(.PhoneInputCountrySelect):focus,
:focus {
  position: relative;
  z-index: 15;
}

/* #react-select-5-listbox, #react-select-9-listbox */
.ReactSelect__control + .ReactSelect__menu,
.error-outline > .ReactSelect__control + .ReactSelect__menu {
  z-index: 16;
}

.error-outline {
  z-index: 13;
}


/* #react-select-5-listbox, #react-select-9-listbox */
/* .ReactSelect__control + .ReactSelect__menu,
.error-outline > .ReactSelect__control,
.error-outline > .ReactSelect__menu {
  z-index: 16;
}

.ReactSelect-outline .ReactSelect__menu,
.ReactSelect-outline .ReactSelect__menu * {
  z-index: 17;
}

.ReactSelect-outline.error-outline {
  z-index: 15;
} */


/**************** 
Utility classes 
****************/

/* For debugging! */
.debug {
  outline: 2px solid hotpink;
}

/* Setting width */
.container {
  inline-size: 90%;
  margin-inline: auto;
}
.cardForm-container {
  inline-size: min(100% - 1rem, var(--container-max, 60ch));
  margin-inline: auto;
}

.DGContainerMax {
  --container-max: 100ch;
}

.no-measure {
  --container-max: 120ch;
}

.measure-ratio {
  max-inline-size: calc(var(--measure) * var(--ratio));
}

.measure {
  max-inline-size: var(--measure);
}

.half-measure {
  max-inline-size: calc(var(--measure) / 2);
}

/* Margins */
.margin-inline-start-auto {
  margin-inline-start: auto;
}

.margin-block-start {
  margin-top: var(--space, 1rem);
}

/* Generic flex row */
.flex_container {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

/* Other */
.hideOverflow-x {
  overflow-x: hidden;
  position: relative;
}

/****************
General styling 
****************/

body {
  background-attachment: fixed;
  background-color: var(--clr-background-solid,var(--clr-neutral-400));
  background-image: linear-gradient(
    var(--clr-background-upper,var(--clr-clear)),
    var(--clr-background-lower,var(--clr-clear))
  );
  margin-bottom: 1.5rem;
}

.copyright {
  position: fixed;
  bottom: 0px;
  height: 1.5rem;
  padding: 0.25rem;
  width: 100%;
  text-align: right;
  font-size: 0.75rem;
  background-image: linear-gradient(#FFF0,#FFF,#FFF,#FFF);
}

/* Solid red that is behind the card */
.workflow::before {
  position: absolute;
  content: "";
  left: -10%;
  top: 0;
  width: 120%;
  height: 4rem;
  background-color: var(--clr-primary-400);
  z-index: -1;
}

.card {
  padding: var(--s1);
  background-color: var(--clr-neutral-100);
  border-radius: var(--br-large);
  box-shadow: var(--box-shadow);
}

:where(.card svg) {
  color: var(--clr-accent-400);
}

/* Thick accent line under card__form title */
.card h2:after {
  height: 5px;
  top: calc(var(--space, 1.5rem) / 2);
}

/* Thin accent line between subsections of the flow - *don't* apply to the first section! */
.card .accent:before {
  height: 2px;
  top: calc(-1 * calc(var(--space-bg, 1.5rem) / 2));
}

.card h2:after,
.card .accent::before {
  content: " ";
  display: block;
  position: relative;
  width: 100%;
  background-color: var(--clr-accent-400);
}

/* Inputs */
/* to align label and radio button/checkboxes */
.label-input__align {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

/* Styles came from: https://moderncss.dev/pure-css-custom-styled-radio-buttons/ */
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--clr-neutral-100);
  margin: 0;
  font: inherit;
  color: var(--clr-accent-400);
  height: 1rem;
  width: 1rem;
  border: 2px solid currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
}
input[type="radio"]::before {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--clr-neutral-900);
}
input[type="radio"]:checked::before {
  transform: scale(1);
}

input[id="readyAt"] {
  width: 60%;
}

input,
input[type="number"],
select,
option {
  font-size: inherit;
  font-family: inherit;
  padding: 1rem;
  height: 100%;
  border: var(--border-default);
  border-width: 1px;
  width: 100%;
  background-color: var(--clr-neutral-100);
  max-inline-size: inherit;
}

/*
 * Page Details
 * The adress drop down is too wide and we need to force column changes
 */

#sender-key,
#receiver-key {
  /* padding: 1rem .1rem; */
  text-overflow: ellipsis;
}

@media (min-width: 950px) { /* Force two column */
  .cluster.force2column {
    gap: 0;
  }
  .force2column > .stack {
    width: min(100%, calc((var(--container-max) - 2*var(--s1) - 1rem) / 2));
  }
  
  .force2column > :first-child {
    margin-right: 1rem;
  }
}

@media (max-width: 950px) { /* Force one column */
  .force2column > .stack {
    width: 100%;
  }
}

/* End Page Details */

/* React-Select box, styling to match other inputs */
div.ReactSelect__control,
div.ReactSelect__control:hover {
  line-height: normal;
  height: 100%;
  border: var(--border-default);
}

div.ReactSelect__value-container {
  padding: 1rem 0.5rem 1rem 1rem;
}

div.ReactSelect__single-value,
div.ReactSelect__input-container {
  margin: 0;
  padding: 0;
  color: inherit;
}

/* Override the error colour so everything is not yellow and override the React-select defaults */
div.ReactSelect__option {
  color: var(--clr-neutral-900);
  background-color: var(--clr-neutral-100);
}

div.ReactSelect__option--is-focused {
  background-color: var(--clr-neutral-100);
}

div.ReactSelect__option--is-selected {
  color: var(--clr-neutral-900);
  background-color: var(--clr-selected);
}

div.ReactSelect__option .no-depot {
  color: var(--clr-neutral-700);
}

div.ReactSelect__option--is-selected .no-depot {
  color: var(--clr-validation-invalid-400);
}

div.ReactSelect__option:hover,
div.ReactSelect__option .no-depot:hover {
  background-color: var(--clr-hover);
}


.PhoneInput > input[type="tel"] {
  max-inline-size: none;
  font-size: inherit;
  font-family: inherit;
}

textarea {
  resize: none;
  border: var(--border-default);
  inline-size: 100%;
  min-block-size: calc(var(--measure) / 3);
  border-color: var(--clr-accent-400);
  background-color: var(--clr-background-field,var(--clr-neutral-400));
  overflow-y: auto;
}

/* Chrome, Safari, Edge, Opera */
.input-number-no-controls::-webkit-outer-spin-button,
.input-number-no-controls::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-number-no-controls {
  appearance: auto;
  -moz-appearance: textfield;
}

/* Buttons */
.btn {
  text-decoration: none;
  color: var(--clr-neutral-100);
  background-color: var(--clr-accent-400);
  padding: 0.75rem 1.25rem;
  border-radius: var(--br-sml);
}

.btn__light {
  color: var(--clr-neutral-900);
  background-color: var(--clr-neutral-100);
  border: var(--border-default);
  text-align: center;
  width: 16rem;
}

.btn__link {
  text-decoration: underline;
  color: blue;
  background-color: transparent;
}

.btn__link:disabled {
  color: var(--clr-neutral-700);
  cursor: default;
}

.btn__add.margin-block-end {
  margin-block-end: 10.45rem;
}

.btn__add {
  align-self: flex-end;
}

.btn__add svg,
.btn__delete svg {
  min-inline-size: 1rem;
}

.btn svg {
  color: var(--clr-secondary-400,var(--clr-neutral-100));
}

.btn__light svg {
  color: var(--clr-accent-400);
}

.btn__add {
  opacity: 50%;
  background-color: var(--clr-validation-valid-500);
  border-radius: var(--br-large);
}

.btn__delete {
  opacity: 50%;
  background-color: var(--clr-primary-400);
  border-radius: var(--br-large);
}

.btn__add svg,
.btn__delete svg {
  color: var(--clr-neutral-100);
}

.btn__add:hover svg,
.btn__delete:hover svg,
.btn__add:focus svg,
.btn__delete:focus svg {
  color: var(--clr-neutral-100);
}

button:hover,
button:focus,
.btn:hover,
.btn:focus {
  cursor: pointer;
}

.btn:hover,
.btn:focus {
  background-color: var(--clr-btn-bg-hover,var(--clr-neutral-900));
}

.btn__add:hover,
.btn__add:focus,
.btn__delete:hover,
.btn__delete:focus {
  opacity: 100%;
}

.btn__add:hover,
.btn__add:focus {
  background-color: var(--clr-validation-valid-500);
}

.btn__delete:hover,
.btn__delete:focus {
  background-color: var(--clr-primary-400);
}

.checked {
  background-color: var(--clr-accent-400);
  border-color: var(--clr-accent-400);
}

.btn.error-outline.checked {
  color: var(--clr-neutral-900);
  background-color: var(--clr-validation-invalid-400);
}

.error-outline.checked:hover,
.error-outline.checked:focus {
  color: var(--clr-neutral-100);
  background-color: var(--clr-neutral-900);
}

.btn.checked,
.btn.checked svg,
.btn:hover,
.btn:hover svg,
.btn:focus,
.btn:focus svg {
  color: var(--clr-btn-fg-hover,var(--clr-neutral-100));
}

a.btn:hover svg,
a.btn:focus svg {
  color: var(--clr-primary-400);
}

.disabled {
  opacity: 50%;
  background-color: var(--clr-neutral-700);
}

.disabled:hover,
.disabled:focus {
  opacity: 55%;
  background-color: var(--clr-neutral-700);
}

/****************
Layout 
****************/
/* table :/ */
table {
  padding: 0;
  width: 100%;
  text-align: center;
  border: var(--border-default);
  border-collapse: collapse;
}

thead,
th {
  color: var(--clr-neutral-100);
  background-color: var(--clr-accent-400);
  /* border: 2px solid var(--clr-accent-400); */
}

thead {
  border: var(--border-solid,var(--border-default));
}

thead tr {
  border: none;
}

tr {
  border: var(--border-solid,var(--border-default));
  border-left: none;
  border-right: none;
}

tr:nth-child(odd) {
  background-color: var(--clr-neutral-100, white);
}

tr:nth-child(even) {
  background-color: var(--clr-background-field,var(--clr-neutral-400, lightgrey));
}

tr > * + * {
  border-left: 1px solid var(--clr-neutral-900);
}

td,
th {
  padding-inline: 0.25rem;
}

@media (max-width: 1250px) {
  td,
  th {
    padding: 0;
    max-inline-size: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
/* end table */

.stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stack-center {
  justify-content: center;
}

.stack > * {
  margin-block: 0;
}

.stack > * + * {
  margin-block-start: var(--space, 1.5rem);
}

.stack .accent:not(:first-child) {
  --space-bg: 3rem;
  margin-block-start: var(--space-bg);
}

.stack > .accent:first-child:before {
  /* outline: 2px solid pink; */
  height: 0;
}

.box {
  padding: var(--s1);
  border: var(--border-dash, var(--border-default));
}

.center {
  box-sizing: content-box;
  margin-inline: auto;
  padding-inline: 1rem;
  padding-block-end: 1rem;
  /* max-inline-size: max-content; */
}

.summary-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  gap: var(--space, 1rem);
  margin-block-start: 0.75rem;
}

.summary-row > * {
  flex-grow: 1;
  flex-basis: 50%;
}

.cluster {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space, 1rem);
  justify-content: flex-start;
  align-items: center;
}

.cluster-outer {
  justify-content: space-between;
}

.cluster-end {
  justify-content: flex-end;
}

.cluster-no-wrap {
  flex-wrap: nowrap;
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-gap: 1rem;
}

@supports (width: min(260px, 100%)) { /* LD0011679 Increased from min(250px, 100%) */
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(min(260px, 100%), 1fr)); /* LD0011679 Increased from min(250px, 100%) */
  }
}

/* Modal classes */
#modal-root > :not(:last-child) {
  display: none;
}

.modal {
  position: fixed;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  opacity: 100%;
  z-index: 20;
}

.modal.contain {
  --margin: 2rem;
  overflow: auto;
  overscroll-behavior: contain;
  max-inline-size: calc(100% - (var(--margin) * 2));
  max-block-size: calc(100% - (var(--margin) * 2));
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  block-size: 100vh;
  inline-size: 100vw;
  background-color: rgb(0, 0, 0, 0.75);
  z-index: 19;
}

.lockscroll {
  overflow-y: hidden;
}

/* Loader classes */
.react-spinner > * {
  background-color: var(--clr-accent-400) !important;
}