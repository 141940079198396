/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  newQuote.css
Author:    Aaron D. Muilne
Version:   0.10
Description:
New Pickup/Quote styles.

History:
0.03    22-04-21 ADM   Moved cluster-no-wrap to index.css
0.02    22-04-19 ADM   Modified cluster-no-wrap to justify-content: space-between. This was done to allow flex-grow to become apparent. Shrunk margin-block-end of btn__add to 5.5rem on wider screens. This better aligns the add item button with the bottom btn__remove. Added Media query of min-width: 701px to switch the grid from 2 columns to 4 columns. While undesirable, this maintains visual cohesion of the grid. Set .error-msg to align-self as center when in a freight card. This aligns the icon under the btn__delete. Reimplemented checkbox__dg as checkbox__hidden
0.01    22-04-14 ADM   Modifier classes for the revamped freight card and page details.
0.00	22-02-22 ADM   Created.
----------------------------------- */
.freightCard .error-msg {
    align-self: center;
}

.four-col, .six-col {
    /* border: var(--border-default);
    border-color: var(--clr-accent-400); */
    grid-template-columns: repeat(2, 50%);;
    gap: 0rem;
}

.four-col .stack > * + *, .six-col .stack > * + * {
    margin-block-start: 0;
}

.grid.four-col label, .grid.six-col label, .grid.six-col .label { 
    text-align: center;
    color: var(--clr-neutral-100);
    background-color: var(--clr-accent-400);
}

.grid.four-col .label-bg, .grid.six-col .label-bg {
    min-height: 3.35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.grid.six-col .stack:nth-child(2) {
    border-inline-start: 2px solid var(--clr-accent-400);
}

.grid input, .grid select {
    border-color: var(--clr-accent-400);
}

.span-2, .span-4 {
    grid-column: span 2;
}

.grid.four-col p.label + p, .grid.six-col p.label + p {
    border: 1px solid var(--clr-accent-400);
    padding-block: 0.5rem;
}

/* hide checkboxs */
.checkbox__hidden {
    opacity: 0;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    border: none;
  }

label.btn svg {
    margin-block-end: var(--space, 1.5rem);
}

.DG {
    --column: 350px;
    max-width: calc(3rem + calc(3 * var(--column)));
    grid-template-columns: repeat(auto-fit, minmax(min(var(--column), 100%), 1fr));
}

.DG .btn {
    inline-size: fit-content;
}

.DG .btn img {
    width: 150px;
    aspect-ratio: 1;
}

@media (min-width: 701px) {
    .btn__add.margin-block-end {
        margin-block-end: 5.65rem;
    }

    .four-col {
        grid-template-columns: repeat(4, 1fr);
    }
    
    .six-col {
        grid-template-columns: repeat(6, 1fr);
    }

    .span-4 {
        grid-column: span 4;
    }
}