/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  index.css
Author:    Dean B. Leggo
Version:   0.10
Description:
Dashboard specific css

History:
2.00  13-04-22 ADM   dashboard converted to center modifying class
0.01  25-02-22 ADM   Edited - refactored to grid. Added media query. Update colours
0.00	23-02-22 DBL   Created.
----------------------------------- */

.dashboard {
  --space-bg: var(--s4);
  display: flex;
  flex-direction: column;
  block-size: 100%;
  max-inline-size: calc(var(--measure) * var(--ratio));
  border: none;
}

.dashboard h1 {
  text-align: center;
  margin-block-start: 1.5rem;
}

.dashboard .grid {
  margin-block-start: var(--space-bg, 1.5rem);
  grid-gap: var(--space-bg, 1.5rem);
}

.dashboard .card.box.stack {
  /* aspect-ratio: 1; */
  /* inline-size: 15rem; */
  justify-content: center;
  align-items: center;
}

a.card {
  color: inherit;
  text-align: center;
}

a.card:hover, 
a.card:focus, 
a.card:hover svg, 
a.card:focus svg {
  color: var(--clr-btn-fg-hover, var(--clr-neutral-100));
  background-color: var(--clr-btn-bg-hover,var(--clr-neutral-900));
  transform: scale(110%);
  box-shadow: var(--svg-shadow, var(--box-shadow));
}

a.card h2:after {
  height: 0px;
}