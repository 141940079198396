/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  ProgressSteps.css
Author:    Dean B. Leggo
Version:   0.10
Description:
Progress Steps specific css

History:
0.02  16-03-22 ADM   Debugging with JRB - Added 1em width to .header__secondary svg.circleBlack to fix display issue in Safari
0.01  09-03-22 ADM   Increased the specificity of .progress-steps to add padding (overriding the reset ul[class])
0.00	24-02-22 DBL   Created.
----------------------------------- */

.header__secondary .progress-steps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--clr-stepper-primary,white);
  background-color: var(--clr-primary-400);
  padding: 1rem 0;
  max-inline-size: none; /* undoes the universal width */
}

.progress-step {
  flex: 1 1 0px;
}

.progress-part {
  display: flex;
  align-items: stretch;
}

.progress-element {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}

.progress-element-icon {
  display: inline-flex;
  flex-direction: row;
}

.progress-element-text {
  display: inline-block;
  padding: 0 0.5rem; /* space to make the click area larger */
}

.progress-part::before,
.progress-part::after,
.progress-element-icon::before,
.progress-element-icon::after {
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: var(--clr-stepper-primary,var(--clr-neutral-100));
  margin-top: calc(1em - 1px); /* subtract half the height */
}

/* To space everything evenly we need all components to have a before and
 * after, but we don't want the first or last line */
.progress-step:first-child .progress-part::before,
.progress-step:last-child .progress-part::after,
.progress-step:first-child .progress-element-icon::before,
.progress-step:last-child .progress-element-icon::after {
  height: 0px;
}

.header__secondary svg.circleBlack {
  border-radius: 50%;
  color: var(--clr-stepper-primary,var(--clr-neutral-100));
  background-color: var(--clr-stepper-secondary,var(--clr-neutral-900));
  width: 1em;
}

.clickable:hover {
  cursor: pointer;
}
