/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  settings.css
Author:    Aaron D. Muilne
Version:   1.00
Description:
Util classes for Settings, MyUsers and Change Password.

History:
1.00    22-04-22 ADM    Created.
----------------------------------- */

.my-users {
    block-size: 100%;
    overflow-y: auto;
    text-align: center;
    margin-block-start: 0;
    border-left: var(--border-default);
    border-bottom: var(--border-default);
    border-right: var(--border-default);
}

.my-users > button {
    margin-block: 0;
    padding: 1rem;
    border-radius: 0;
    background-color: var(--clr-neutral-400);
    color: var(--clr-neutral-900);
}

.my-users > button.btn.checked {
    background-color: var(--clr-neutral-900);
}

.my-users > button:hover, .my-users > button:focus {
    color: var(--clr-neutral-100);
    background-color: var(--clr-accent-400);
}

.user-form.box {
    border-left: none;
}

.user-form {
    inline-size: var(--width, var(--measure));
}

.user-form input, .user-form select {
    height: auto;
    width: min(300px, 100%);
}

.user-form input[type="checkbox"] {
    width: 25px;
    height: 25px;
}

.set-width {
    --width: 50ch;
}

.set-width-35vw {
    --width: 35vw;
}