/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  index.css
Author:    Dean B. Leggo
Version:   0.10
Description:
Layout specific css

History:
0.02  08-03-22 ADM   Edits - added align-items: center to #topHeader > div, aligning the 'return to dashboard button'
0.01  25-02-22 ADM   Edits - added additional classes, updated colours
0.00	23-02-22 DBL   Created.
----------------------------------- */

.stack span {
  font-size: 0.75em;
}

.companyLogo {
  height: 5em;
}

.portalLogo {
  height: 3em;
}

.header__primary, header nav {
  padding-block: 1rem;
}

.header__primary, .header__secondary, header nav {
  color: var(--clr-secondary-400,var(--clr-neutral-100));
}

.header__primary {
  background-color: var(--clr-header-primary,var(--clr-primary-400));
}

.header__secondary {
  background-color: var(--clr-header-secondary,var(--clr-neutral-900));
}

.header__primary .btn, .header__primary .svg {
  color: var(--clr-neutral-100);
  background-color: var(--clr-header-primary);
}

.header__primary .btn__db {
 border: 2px solid var(--clr-neutral-100);
 /* 
 color: var(--clr-primary-400);
 background-color: var(--clr-neutral-100);
 */
}

.header__primary .btn:hover,
.header__primary .btn:focus {
  color: var(--clr-accent-400);
  background-color: var(--clr-neutral-100);
}

.header__primary .btn__db:hover, .header__primary .btn__db:focus {
  color: var(--clr-btn-fg-hover,var(--clr-accent-400));
  background-color: var(--clr-btn-bg-hover,var(--clr-neutral-100));
  /* color: var(--clr-neutral-100);
  background-color: var(--clr-neutral-900);
  border: 2px solid var(--clr-neutral-900); */
}

.header__primary .btn, .header__secondary .btn {
  text-transform: uppercase;
  font-weight: 600;
  border-radius: var(--br-large);
}

.header__secondary .btn {
  color: var(--clr-accent-400);
  background-color: var(--clr-neutral-100);
}

.header__secondary .btn:hover, 
.header__secondary .btn:focus {
  color: var(--clr-neutral-100);
  background-color: var(--clr-accent-400);
}

.header__secondary .cluster .cluster {
  align-items: stretch;
}

#nav-aside .activeButton {
  color: var(--clr-neutral-100);
  background-color: var(--clr-accent-400);
}

#nav-aside .activeButton:hover,
#nav-aside .activeButton:focus {
  color: var(--clr-neutral-900);
  background-color: var(--clr-neutral-100);
}
