/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  index.css
Author:    Dean B. Leggo
Version:   0.10
Description:
Login specific css

History:
2.01  13-04-22 ADM   Removed redundant styling. Added login and login-img as levers to modify CardForm and related classes
2.00	13-04-22 ADM   Created.
----------------------------------- */
.stack .login-img {
  margin: 2rem auto;
  height: 200px;
}

.login {
  inline-size: calc(var(--measure) / 2);
}

.login label {
  font-weight: 600;
}

.login .cluster-outer {
  justify-content: flex-end;
}