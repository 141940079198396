/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  Tracker.css
Author:    John D. Kohl
Version:   2.00
Description:
Show tracking information for the given consignment.

History:
2.00  12-11-24 JDK   Tracking version 2: CSS updated to support new display paradigm (tabbed stepper)
1.00  21-10-24 JDK   Feature complete, styling finalised.
0.01	10-10-24 JDK   Created to provide styling for the tracking stepper.
----------------------------------- */


.tracking-container::after {
  display: block;
  content: "";
  height: 0.4ch;
  width: calc(100% - 2ch);
  margin: 1ch;
  background-color: var(--clr-accent-400);
}

.tracking-title {
  text-align: center;
  margin-bottom: 1ch;
}

.consignment-info {
  display: flex;
  padding: 1ch;
  justify-content: space-around;
}

.consignment-info div * {
  text-align: left;
}

.stepper-container {
  padding-top: 1ch;
  margin-top: 4ch;
  display: none;
  justify-content: space-between;
  margin-bottom: 4ch;
}

.stepper-container.active {
  display: flex;
}

.stepper-container.loading {
  display: flex;
  justify-content: center;
}

.stepper-container.loading * {
  height: 6rem;
  margin-bottom: 0.5ch;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: auto;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--clr-neutral-700);
  width: calc(100% - 2rem);
  top: 1rem;
  right: calc(50% + 1rem);
}

.stepper-item .step-count {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: var(--clr-neutral-700);
  margin-bottom: 0.5ch;
}

.stepper-item.current {
  font-weight: bold;
}

.stepper-item .step-count * {
  position: absolute;
  top: -3rem;
  max-width: 150%;
  max-height: 150%;
}

.stepper-item.completed .step-count {
  background-color: var(--clr-primary-400);
}

.stepper-item.current .step-count {
  background-color: var(--clr-accent-400);
}

.stepper-item.completed::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--clr-primary-400);;
  top: 1rem;
  /* z-index: 3; */
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.stepper-tabs {
  display: flex;
  justify-content: center;
}

.stepper-tabs button {
  padding: 0.75rem 1.25rem;
  color: var(--clr-neutral-100);
  background-color: var(--clr-accent-400);
}

.stepper-tabs button:hover, button:focus, button.active {
  background-color: var(--clr-neutral-900);
}

.stepper-tabs button:disabled {
  background-color: var(--clr-neutral-700);
}