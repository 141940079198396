/* -----------------------------------
Copyright: Logical Developments 2022.
Project:   ConNote Portal
Filename:  index.css
Author:    John D. Kohl
Version:   2.05
Description:
General css for the Consignments and POD screen.

History:
2.05    08-11-24 JDK    Added styles for tracking button in list header
2.04    04-05-22 DBL    Moved to Documents
2.03    22-04-22 ADM    Moved flex_container to index.css
2.02    22-04-21 ADM    Added hover styles for the buttons. Changed .table to the logical property of block-size instead of height
2.01    22-04-13 ADM    Added the table modifying class with the relevant overrides..
2.00    22-04-13 ADM    Created. Object style is carried over from version 1. Gutted the HTML table in favour of stacked divs
----------------------------------- */
.table {
  block-size: 450px;
  min-inline-size: 15ch;
  overflow-y: scroll;
  text-align: center;
  margin-block-start: 0;
  border-left: var(--border-solid,var(--border-default));
  border-bottom: var(--border-solid,var(--border-default));
  border-right: var(--border-solid,var(--border-default));
}

.table > * + * {
  --space: 0rem
}

.table > * {
  padding: 1rem 2rem;
  background-color: var(--clr-trow-odd,var()); /* empty var is necessary for default behaviour. Yes. I know. I hate it too. JDK */
}

.table > *:nth-child(even) {
  background-color: var(--clr-trow-even,var(--clr-neutral-400));
}

.table button:hover {
  color: var(--clr-btn-fg-hover,var(--clr-neutral-100));
  background-color: var(--clr-btn-bg-hover,var(--clr-accent-400));
}

.table button:focus {
  color: var(--clr-btn-fg-checked,var(--clr-neutral-100));
  background-color: var(--clr-btn-bg-checked,var(--clr-accent-400));
}

.table button:active, .table .checked {
  color: var(--clr-neutral-100);
  background-color: var(--clr-btn-bg-checked,var(--clr-neutral-900));
  /* background-color: var(--clr-btn-hl,var(--clr-neutral-900)); */
}

.table-heading {
  color: var(--clr-neutral-100);
  background-color: var(--clr-accent-400);
  padding: 0.5rem 1rem;
  border: var(--border-solid,var(--border-default));
}

.search {
  padding-inline: 1rem;
  border: var(--border-solid,var(--border-default));
}

.btn-inline {
  min-width: fit-content;
}

object[type="application/pdf"] {
  border-top: var(--border-solid,var(--border-default));
  border-right: var(--border-solid,var(--border-default));
  border-bottom: var(--border-solid,var(--border-default));
  background-color: var(--clr-neutral-700);
  inline-size: calc(var(--ratio) * var(--measure));
}
